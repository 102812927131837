import { createContext } from "react";
import {
  Periodo,
  Medidas,
  Variables,
  Entorno,
  Canal,
  Detalle,
} from "./utils/constants";
import { Fill, Font } from "exceljs";

const currentYear = new Date().getFullYear();

export const defaults: { weeks: IWeeks; filters: IFilter } = {
  weeks: [
    { year: currentYear, week: 1 },
    { year: currentYear, week: 1 },
  ],
  filters: {
    periodo: Periodo.Semana,
    medidas: Medidas.Ventas,
    variables: Variables.Total,
    entorno: Entorno.Total,
    misdatos: 0,
    detalle: Detalle.Desglose,
    segmentos: [],
    especialidades: [],
    mustSelectChain: false,
    segmentType: 0, // 0: tipo servicio, 1: tipo producto
    segment: 0, // 0: todos, 1: QSR, 2: Casual, n: Otros
    lever: 0,
    leverType: 0, // 0: Total, 1: Canal, 2: Momento, 3: Región, 4: Ubicación
    channel: 0, // 0: Total, 1: Llevar, 2: Local, 3: Domicilio
    moment: 0, // 0: Total, 1: Mañana, 2: Tarde, 3: Comida diario, 4: Cena diario, 5: Comida fin de semana, 6: Cena fin de semana
    region: 0, // 0: Total, 1: Madrid, 2: Barcelona, 3: Región 1, 4: Región 2, 5: Región 3, 6: Región 4
    location: 0, // 0: Total, 1: Calle, 2: Centro comercial, 3: Resto
  },
};

export type IFilters = {
  limitedPeriodo: boolean;
  showMedidas: boolean;
  hasTicketMedio: boolean;
  isDesglose: boolean;
  showEntorno: boolean;
  showSegmentos: boolean;
  showMyData?: boolean;
  onlyYears?: boolean;
  showCanal?: boolean;
  mustSelectChain?: boolean;
  showVariable?: boolean;
};

export type IExcelColor = {
  main: {
    bg: Fill;
    font: Partial<Font>;
  };
  title: {
    bg: Fill;
    font: Partial<Font>;
  };
};

export interface IUsuario {
  Internal?: string;
  Error?: string;
  UUID: string;
  Nombre: string;
  Email: string;
  Pass: string;
  Compania: ICompania;
  Cadena: ICadena;
  Rol: IRolUsuario;
  hasVentas: boolean;
  Ventas: IVenta[];
}
export interface IRolUsuario {
  Internal?: string;
  Error?: string;
  UUID: string;
  Descripcion: string;
  canRead: boolean;
  canWrite: boolean;
  canDownload: boolean;
  isAdmin: boolean;
  Usuarios: IUsuario[];
}
export interface ICompania {
  Internal?: string;
  Error?: string;
  UUID: string;
  Nombre: string;
  hasCadenas: boolean;
  Cadenas: ICadena[];
  hasUsuarios: boolean;
  Usuarios: IUsuario[];
}
export interface ICadena {
  Internal?: string;
  Error?: string;
  UUID: string;
  Nombre: string;
  Compania: ICompania;
  Segmento: ISegmento[];
  Especialidad: IEspecialidad[];
  Contacto?: IContacto;
  CargaIVA?: boolean;
  Ratio: number;
  hasUsuarios: boolean;
  Usuarios: IUsuario[];
  hasVentas: boolean;
  Ventas: IVenta[];
}
export interface ISegmento {
  Internal?: string;
  Error?: string;
  UUID: string;
  Descripcion: string;
  hasCadenas: boolean;
  Cadenas: ICadena[];
}
export interface IEspecialidad {
  Internal?: string;
  Error?: string;
  UUID?: string;
  Descripcion?: string;
  hasCadenas: boolean;
  Cadenas: ICadena[];
}
export interface IContacto {
  Error?: string;
  Nombre?: string;
  Email?: string;
  Telefono?: string;
}

export interface IEvento {
  Error?: string;
  Semana: number;
  Anyo: number;
  UUID: string;
  Evento: string;
  Comentario: string;
}

interface IDesglose {
  Totales: number;
  Canal: {
    Delivery: number;
    TakeAway: number;
    Local: number;
  };
  Ubicacion: {
    Calle: number;
    CC: number;
    Concesiones: number;
    Otros: number;
  };
  Region: {
    Z1: number;
    Z2: number;
    Z3: number;
    Z4: number;
    Z5: number;
    Z6: number;
  };
  Momento: {
    T1: number;
    T2: number;
    T3: number;
    T4: number;
    T5: number;
    T6: number;
  };
}
export interface IVenta {
  Internal?: string;
  Error?: string;
  UUID: string;
  Usuario: IUsuario;
  Semana: number;
  Anyo: number;
  LastYear?: {
    VentasComparables: number;
    TicketsComparables: number;
    LocalesComparables: number;
    LocalesRepComparables: number;
  };
  Actual: {
    Ventas: {
      Bruto: IDesglose;
      Comparable: IDesglose;
    };
    Tickets: {
      Bruto: IDesglose;
      Comparable: IDesglose;
    };
    Locales: {
      Aperturas: number;
      Cierres: number;
      Bruto: {
        Total: number;
        Reportan: number;
        Self: number;
        Delivery: number;
      };
      Comparable: {
        Total: number;
        Reportan: number;
      };
    };
  };
  Anterior: {
    Ventas: {
      Bruto: IDesglose;
      Comparable: IDesglose;
    };
    Tickets: {
      Bruto: IDesglose;
      Comparable: IDesglose;
    };
    Locales: {
      Aperturas: number;
      Cierres: number;
      Bruto: {
        Total: number;
        Reportan: number;
        Self: number;
        Delivery: number;
      };
      Comparable: {
        Total: number;
        Reportan: number;
      };
    };
  };
  Modelizacion: number;
  Ratio: number;
  Cadena: ICadena;
}

export type IWeek = { year: number; week: number };
export type IWeeks = IWeek[];
export type IFilter = {
  periodo: number;
  medidas: number;
  variables: number;
  entorno: number;
  misdatos: number;
  detalle?: number;
  segmentos: string[];
  especialidades: string[];
  palanca?: number;
  mustSelectChain?: boolean;
  segmentType: number;
  segment: number;
  leverType: number;
  lever: number;
  channel?: number;
  moment?: number;
  region?: number;
  location?: number;
};
export type IContextProps = {
  semanas: IWeeks;
  setSemanas: Function;
  filters: IFilter;
  setFilters: Function;
  section: number;
  setSection: Function;
  sectionItem: 0;
  setSectionItem: Function;
  semanasDatos: IWeeks;
  cadenas: string[];
  user: string;
  userName: string;
  isAdmin?: boolean;
};
const AppContext = createContext<IContextProps>({
  semanas: defaults.weeks,
  setSemanas: Function,
  filters: defaults.filters,
  setFilters: Function,
  section: 0,
  setSection: Function,
  sectionItem: 0,
  setSectionItem: Function,
  semanasDatos: defaults.weeks,
  cadenas: [],
  user: "",
  userName: "",
});
export { AppContext as default };
